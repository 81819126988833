import { createEntityAdapter, EntityState } from '@ngrx/entity';
import { Loading } from '@shared/models/loading.model';
import { OpenInvoice } from '../models/open-invoice-model';

interface OpenInvoiceInitialState {
  status: Loading;
  selectedOpenInvoice: number | string | null;
  orderConfirmationPdf: string;
  invoicePdf: string;
}

export interface OpenInvoiceEntityState
  extends EntityState<OpenInvoice>,
    OpenInvoiceInitialState {}

export const adapter = createEntityAdapter<OpenInvoice>({
  selectId: (state) => state.abacusInvoiceNo,
});

export const initialState = adapter.getInitialState<OpenInvoiceInitialState>({
  status: Loading.Idle,
  selectedOpenInvoice: '',
  orderConfirmationPdf: '',
  invoicePdf: '',
});
