import {CommonModule} from '@angular/common'
import {NgModule} from '@angular/core'
import {MatButtonModule} from '@angular/material/button'
import {MatIconModule} from '@angular/material/icon'
import {MatListModule} from '@angular/material/list'
import {MatToolbarModule} from '@angular/material/toolbar'
import {RouterModule} from '@angular/router'
import {NavigationComponent} from './components/navigation/navigation.component'
import {SidenavListComponent} from './components/sidenav-list/sidenav-list.component'

@NgModule({
  declarations: [NavigationComponent, SidenavListComponent],
  imports: [
    CommonModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatListModule,
    RouterModule
  ],
  exports: [NavigationComponent, SidenavListComponent]
})
export class CoreModule {
}
