import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {OrderConfirmation} from '@order-confirmations/models/order-confirmation.model'
import {Loading} from '@shared/models/loading.model'
import {formatDateToISOString} from '@shared/utils/date.utils'

interface OrderConfirmationsInitialState {
  status: Loading
  selectedOrderConfirmation: number | null
  orderConfirmationPdf: string | null
  deliveryChainChangeInfo: string | null
  orderPdf: string | null
}

export interface OrderConfirmationsEntityState
  extends EntityState<OrderConfirmation>,
    OrderConfirmationsInitialState {
}

function sortComparer(a: OrderConfirmation, b: OrderConfirmation): number {
  const aDateTime = new Date(
    formatDateToISOString(a.deliveryDateMax, '.')
  ).getTime()
  const bDateTime = new Date(
    formatDateToISOString(b.deliveryDateMax, '.')
  ).getTime()

  return bDateTime - aDateTime
}

export const adapter = createEntityAdapter<OrderConfirmation>({
  selectId: (state) => state.orderConfirmationNo
})

export const initialState =
  adapter.getInitialState<OrderConfirmationsInitialState>({
    status: Loading.Idle,
    selectedOrderConfirmation: null,
    orderConfirmationPdf: null,
    deliveryChainChangeInfo: null,
    orderPdf: null
  })
