import {Component, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {SnackbarFacadeService} from "@shared/facades/snackbar-facade.service";
import {MessageService} from "@shared/services/message.service";
import {MessageRequest} from "@shared/models/message-request.model";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  templateUrl: './send-message-dialog.component.html',
  styleUrls: ['./send-message-dialog.component.css'],
})
export class SendMessageDialogComponent implements OnInit, OnDestroy {

  formSubscription: Subscription;
  message: MessageRequest | undefined;
  public saveForm = new FormGroup({
    email: new FormControl('', Validators.required),
    text: new FormControl('', Validators.required)
  });
  private loadingBehaviourSubject = new BehaviorSubject<boolean>(false)
  public readonly loading$ = this.loadingBehaviourSubject.asObservable()

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private snackbarFacade: SnackbarFacadeService,
    private messageService: MessageService
  ) {
    this.message = config.data
  }

  ngOnInit(): void {
    this.initiateForm();
  }

  initiateForm(): void {
    this.saveForm.get('email').setValue(this.message.email);
  }

  cancel() {
    this.ref.close();
  }

  send() {

    this.loadingBehaviourSubject.next(true)
    this.messageService
      .sendDatamolinoMessage({
          ...this.message,
          email: this.saveForm.get('email').value,
          text: this.saveForm.get('text').value
        }
      )
      .pipe(
        finalize(() => {
          this.loadingBehaviourSubject.next(false)
        })
      )
      .subscribe((response) => {
        this.snackbarFacade.openSnackbar({
          //message: response.backendMessage,
          message: "Message was sent succesfully",
          config: {duration: 5000}
        })

        this.ref.close(true);
      })
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
