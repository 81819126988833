import {User} from '@auth/models/user.model'
import {createFeatureSelector, createSelector} from '@ngrx/store'
import {RootState} from 'src/app/reducers'
import * as fromAuth from '../reducers/auth.reducer'

export const selectAuthState = createFeatureSelector<fromAuth.State>(
  fromAuth.featureKey
)

export const selectAuthUser = createSelector<RootState,
  fromAuth.State,
  User | null>(selectAuthState, (state) => state.user)
