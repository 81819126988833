<form [formGroup]="saveForm">
  <div class="grid formgrid">
    <div class="field col-12 md:col-12 p-fluid">
      <label for="email">Email:</label>
      <input formControlName="email" id="email" pInputText type="text" />
    </div>
    <div class="field col-12 md:col-12 p-fluid">
      <label for="text">Text:</label>
      <textarea
        formControlName="text"
        id="text"
        pInputTextarea
        rows="5"
      ></textarea>
    </div>
    <div class="field col-12 md:col-12 text-right">
      <p-button
        (onClick)="cancel()"
        icon="pi pi-times"
        label="Abbrechen"
        styleClass="p-button-danger mr-2"
      ></p-button>
      <p-button
        (onClick)="send()"
        [disabled]="(loading$ | async) || saveForm.invalid"
        icon="pi pi-check"
        label="Senden"
      ></p-button>
    </div>
  </div>
</form>
