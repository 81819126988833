import {Component, EventEmitter, Input, Output} from '@angular/core'
import {User} from '@auth/models/user.model'

@Component({
  selector: 'nav-list',
  templateUrl: './sidenav-list.component.html',
  styleUrls: ['./sidenav-list.component.css']
})
export class SidenavListComponent {
  @Input() user: User | null
  @Output() sidenavClose = new EventEmitter()
  @Output() logout = new EventEmitter()

  constructor() {
  }

  onSidenavClose(): void {
    this.sidenavClose.emit()
  }

  logoutAction(): void {
    this.logout.emit()
  }
}
