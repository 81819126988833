import {Component, Input} from '@angular/core'

@Component({
  selector: 'status-badge',
  templateUrl: './status-badge.component.html',
  styleUrls: ['./status-badge.component.css']
})
export class StatusBadgeComponent {
  @Input() color = ''
  @Input() text = ''

  constructor() {
  }
}
