import {Component, EventEmitter, Input, Output} from '@angular/core'
import {User} from '@auth/models/user.model'

@Component({
  selector: 'nav-header',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css']
})
export class NavigationComponent {
  @Input() user: User | null
  @Output() public sidenavToggle = new EventEmitter()

  constructor() {
  }

  onToggleSidenav(): void {
    this.sidenavToggle.emit()
  }
}
