import {Injectable} from '@angular/core'
import {MatSnackBar} from '@angular/material/snack-bar'
import {Actions, createEffect, ofType} from '@ngrx/effects'
import {map} from 'rxjs/operators'
import * as SnackbarActions from '../actions/snackbar.actions'

@Injectable()
export class SnackbarEffects {
  openSnackBar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SnackbarActions.openSnackbar),
        map((payload) => {
          this.matSnackBar.open(
            payload.message,
            payload.action || 'Schließen',
            payload.config
          )
        })
      )
    },

    {dispatch: false}
  )

  closeSnackbar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SnackbarActions.closeSnackbar),
        map(() => {
          this.matSnackBar.dismiss()
        })
      )
    },

    {dispatch: false}
  )

  constructor(private actions$: Actions, private matSnackBar: MatSnackBar) {
  }
}
