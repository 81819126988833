/* eslint-disable @typescript-eslint/naming-convention */
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {ApiResponse} from '@shared/models/apiresponse.model'
import {Observable} from 'rxjs'
import {SERVER_API_URL} from '../../app.constants';
import {MessageRequest} from "@shared/models/message-request.model";

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private url = SERVER_API_URL
  private auth = 'VdjCKidks7qTEyuU6QeJtezZ3PeCHhZQ2yHJ3kPtcfTN8BjVqU'
  private email = 'd.rausch@muessig.ch'

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application2/json',
      'x-apikey': 'VdjCKidks7qTEyuU6QeJtezZ3PeCHhZQ2yHJ3kPtcfTN8BjVqU'
    })
  }

  constructor(private http: HttpClient) {
  }

  sendDatamolinoMessage(data: MessageRequest
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      `${this.url}/sendRepairRequestMessageToDatamolino?auth=${this.auth}&documentNo=${data.documentNo}&text=${data.text}`,
      this.httpOptions
    )
  }

  sendNewSupplierMessage(supplierName: string, supplierZipCode: string, supplierCity: string, supplierStreet: string,
                         supplierCountry: string, currency: string, supplierTaxCode: string, supplierIBanCode: string,
                         streetNo: string
  ): Observable<ApiResponse> {
    return this.http.get<ApiResponse>(
      `${this.url}/createNewSupplier?auth=${this.auth}&supplierName=${supplierName}
      &supplierZipCode=${supplierZipCode}
      &supplierCity=${supplierCity}
      &supplierStreet=${supplierStreet}
      &supplierCountry=${supplierCountry}
      &currency=${currency}
      &supplierTaxCode=${supplierTaxCode}
      &supplierIBanCode=${supplierIBanCode}
      &streetNo=${streetNo}`,
      this.httpOptions
    )
  }

}
