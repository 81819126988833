import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {EffectsModule} from '@ngrx/effects';
import {TooltipDirective} from '@shared/directives/tooltip.directive';
import {CustomTooltipComponent} from '@shared/directives/tooltip/tooltip.component';
import {SnackbarEffects} from '@shared/effects/snackbar.effects';
import {LoadingComponent} from './components/loading/loading.component';
import {SortByPipe} from './pipes/sort-by.pipe';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {SendMessageDialogComponent} from '@shared/components/send-message-dialog/send-message-dialog.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ButtonModule} from 'primeng/button';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {InputTextModule} from 'primeng/inputtext';
import {SafeHtmlPipe} from '@shared/pipes/safe-html.pipe';
import {StatusBadgeComponent} from '@shared/components/status-badge/status-badge.component';
import {PdfCompareComponent} from '@shared/components/pdf-compare/pdf-compare.component';
import {PdfViewerModule} from 'ng2-pdf-viewer';
import {FieldsetModule} from 'primeng/fieldset';
import {TabViewModule} from 'primeng/tabview';
import {
  SendDatamolinoMessageDialogComponent
} from "@shared/components/send-datamolino-message-dialog/send-datamolino-message-dialog.component";
import {
  DefineNewSupplierDialogComponent
} from "@shared/components/define-new-supplier-dialog/define-new-supplier-dialog.component";
import {DropdownModule} from "primeng/dropdown";

@NgModule({
  declarations: [
    TooltipDirective,
    CustomTooltipComponent,
    SortByPipe,
    LoadingComponent,
    SendMessageDialogComponent,
    SendDatamolinoMessageDialogComponent,
    DefineNewSupplierDialogComponent,
    SafeHtmlPipe,
    StatusBadgeComponent,
    PdfCompareComponent,
  ],
  imports: [
    CommonModule,
    EffectsModule.forFeature([SnackbarEffects]),
    MatSnackBarModule,
    ProgressSpinnerModule,
    ReactiveFormsModule,
    ButtonModule,
    InputTextareaModule,
    InputTextModule,
    PdfViewerModule,
    FieldsetModule,
    TabViewModule,
    DropdownModule,
  ],
  exports: [
    TooltipDirective,
    SortByPipe,
    LoadingComponent,
    SendMessageDialogComponent,
    SendDatamolinoMessageDialogComponent,
    DefineNewSupplierDialogComponent,
    SafeHtmlPipe,
    StatusBadgeComponent,
    PdfCompareComponent,
  ],
})
export class SharedModule {
}
