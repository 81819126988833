<div class="grid">
  <div [ngClass]="{'col-12 md:col-12 my-2 p-0': !label2, 'col-12 md:col-6 my-2 p-0': label2}">
    <p-fieldset>
      <ng-template pTemplate="header">
        <p-button (click)="openPdf(firstPdf)" label="{{label1}}" styleClass="p-button-link"></p-button>
      </ng-template>
      <pdf-viewer
        *ngIf="firstPdf"
        [autoresize]="true"
        [fit-to-page]="true"
        [original-size]="false"
        [render-text]="true"
        [src]="firstPdf"
        style="max-width: 100%; height: 690px"
      ></pdf-viewer>
    </p-fieldset>
  </div>
  <div [ngClass]="{'col-12 md:col-6 my-2 p-0': label2, 'col-12 md:col-12 my-2 p-0': !label2}">
    <p-fieldset *ngIf="secondPdf">
      <ng-template pTemplate="header">
        <p-button (click)="openPdf(secondPdf)" label="{{label2}}" styleClass="p-button-link"></p-button>
      </ng-template>
      <pdf-viewer
        [autoresize]="true"
        [fit-to-page]="true"
        [original-size]="false"
        [render-text]="true"
        [src]="secondPdf"
        style="max-width: 100%; height: 690px"
      ></pdf-viewer>
    </p-fieldset>
  </div>
</div>
