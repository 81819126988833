/* eslint-disable @typescript-eslint/member-ordering */
import {Injectable} from '@angular/core'
import * as AuthActions from '@auth/actions/auth.actions'
import {RolesEnum, User} from '@auth/models/user.model'
import {selectAuthUser} from '@auth/selectors/auth.selectors'
import {AuthService} from '@auth/services/auth.service'
import {Store} from '@ngrx/store'
import {BehaviorSubject, Observable} from 'rxjs'
import {tap} from 'rxjs/operators'

@Injectable({
  providedIn: 'root'
})
export class AuthFacadeService {
  user$: Observable<User | null>
  public readonly role$: Observable<RolesEnum | ''>
  private roleBehaviourSubject = new BehaviorSubject<RolesEnum | ''>('')

  constructor(private readonly store: Store, private authService: AuthService) {
    this.user$ = this.store
      .select(selectAuthUser)
      .pipe(tap((user) => this.setAuthRoles(user)))
    this.role$ = this.roleBehaviourSubject.asObservable()
  }

  setAuthUser(user: User): void {
    this.store.dispatch(AuthActions.setAuthUser({user}))
  }

  clearAuthUser(): void {
    this.store.dispatch(AuthActions.clearAuthUser())
    this.authService.removeAuthUserToken()
  }

  setAuthRoles(user: User | null): void {
    if (!user) {
      this.roleBehaviourSubject.next('')

      return
    }

    const role = this.getUserRole(user)
    this.roleBehaviourSubject.next(role)
  }

  getUserRole = (user: User): RolesEnum | '' => {
    const {roles} = user

    const role = roles.reduce<RolesEnum | ''>((previousValue, currentValue) => {
      if (currentValue.name === RolesEnum.Supplier) {
        previousValue = RolesEnum.Supplier
      }
      if (currentValue.name === RolesEnum.Technician) {
        previousValue = RolesEnum.Technician
      }

      return previousValue
    }, '')

    return role
  }
}
