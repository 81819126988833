import {Pipe, PipeTransform} from '@angular/core'

@Pipe({
  name: 'sortBy'
})
export class SortByPipe implements PipeTransform {
  transform(value: any[], column: string): unknown {
    if (value) {
      return value.slice().sort((a, b) => b[column] - a[column])
    }

    return value
  }
}
