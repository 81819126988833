import {animate, style, transition, trigger} from '@angular/animations'
import {Component, Input, TemplateRef} from '@angular/core'
import {TooltipComponent} from '@angular/material/tooltip'

@Component({
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.css'],
  animations: [
    trigger('tooltip', [
      transition(':enter', [
        style({opacity: 0}),
        animate(100, style({opacity: 1}))
      ]),
      transition(':leave', [animate(100, style({opacity: 0}))])
    ])
  ]
})
export class CustomTooltipComponent extends TooltipComponent {
  @Input() text: string
  @Input() contentTemplate: TemplateRef<any>
}
