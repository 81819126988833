import {Injectable} from '@angular/core';

interface Base64TypeAndData {
  contentType: string;
  content: string;
}

@Injectable({
  providedIn: 'root',
})
export class PdfService {
  base64ToBlob(base64Data: string, contentType = '', sliceSize = 512): Blob {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, {type: contentType});
    return blob;
  }

  createBlob(base64EncodedString: string): Blob {
    const rawBase64 = base64EncodedString.replace(
      'data:application/pdf;base64,',
      ''
    );

    return this.base64ToBlob(rawBase64, 'application/pdf');
  }

  openPDF(base64EncodedString: string): void {
    const blob = this.createBlob(base64EncodedString);

    const fileURL = URL.createObjectURL(blob);
    window.open(fileURL);
  }

  async toBase64(file: File): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = () => reject(reader.error);
    });
  }

  getBase64TypeAndData(base64: string): Base64TypeAndData {
    const [data, content] = base64.split(',');
    const [, typeBase64] = data.split(':');
    const [contentType] = typeBase64.split(';');

    return {
      contentType,
      content,
    };
  }
}
