import {Component, NgModule, OnChanges, OnDestroy, OnInit, SimpleChanges} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {SnackbarFacadeService} from "@shared/facades/snackbar-facade.service";
import {MessageService} from "@shared/services/message.service";
import {MessageRequest} from "@shared/models/message-request.model";
import {Prov3Invoice} from "../../../prov3-invoice/models/prov3-invoice.model";
import {SupplierData} from "../../../prov3-invoice/models/supplier-Data.model";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  templateUrl: './define-new-supplier-dialog.component.html',
  styleUrls: ['./define-new-supplier-dialog.component.css'],
})

export class DefineNewSupplierDialogComponent implements OnDestroy, OnInit {
  formSubscription: Subscription;
  public createNewSupplierForm = new FormGroup({
    supplierNameInput: new FormControl('', Validators.required),
    supplierZipCodeInput: new FormControl('', Validators.required),
    supplierCityInput: new FormControl('', Validators.required),
    supplierStreetInput: new FormControl('', Validators.required),
    supplierCountryInput: new FormControl('', Validators.required),
    supplierIBanCodeInput: new FormControl('', [Validators.required, Validators.maxLength(21), Validators.minLength(21)]),
    dropdownCurrency: new FormControl('', Validators.required),
    supplierStreetNoInput: new FormControl('', Validators.required),
    supplierTaxCodeInput: new FormControl('', Validators.maxLength(20)),
  });
  private prov3Invoice: Prov3Invoice | undefined;
  private selectedSupplier: SupplierData | undefined;

  private loadingBehaviourSubject = new BehaviorSubject<boolean>(false)
  public readonly loading$ = this.loadingBehaviourSubject.asObservable()
  selectedCurrency: any;
  selectedCountry: any;
  currencies = [
    { name: "CHF" },
    { name: "EUR" }];
  countries = [
    { name: "Schweiz" },
    { name: "Deutschland" },
    { name: "Österreich" }];

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private snackbarFacade: SnackbarFacadeService,
    private messageService: MessageService,
  ) {
    this.prov3Invoice = config.data.deleteInvoice
    this.selectedSupplier = config.data.selectedSupplier;
  }

  ngOnInit(): void {
    this.initiateForm();
  }

  private initiateForm() {
    const supplierName = this.selectedSupplier.name;
    const nonNumbersOnly = supplierName.replace(/\d/g, '');
    this.createNewSupplierForm.get('supplierNameInput').setValue(nonNumbersOnly);
    this.createNewSupplierForm.get('supplierTaxCodeInput').setValue(this.prov3Invoice.taxCode);
    this.createNewSupplierForm.get('supplierIBanCodeInput').setValue(this.prov3Invoice.ibanNo);
  }

  cancel() {
    this.ref.close();
  }

  send() {
    this.loadingBehaviourSubject.next(true)
    const supplierName = this.createNewSupplierForm.get('supplierNameInput').value;
    const supplierZipCode = this.createNewSupplierForm.get('supplierZipCodeInput').value;
    const supplierCity = this.createNewSupplierForm.get('supplierCityInput').value;
    const supplierStreet = this.createNewSupplierForm.get('supplierStreetInput').value;
    const supplierCountry = this.createNewSupplierForm.get('supplierCountryInput').value;
    const currency = this.createNewSupplierForm.get('dropdownCurrency').value;
    const supplierTaxCode = this.createNewSupplierForm.get('supplierTaxCodeInput').value;
    const supplierIBanCode = this.createNewSupplierForm.get('supplierIBanCodeInput').value;
    const supplierStreetNoInput = this.createNewSupplierForm.get('supplierStreetNoInput').value;
    this.messageService
      .sendNewSupplierMessage(
        supplierName, supplierZipCode, supplierCity, supplierStreet, "CH", "CHF", supplierTaxCode, supplierIBanCode, supplierStreetNoInput
      )
      .pipe(
        finalize(() => {
          this.loadingBehaviourSubject.next(false)
        })
      )
      .subscribe((response) => {
        this.snackbarFacade.openSnackbar({
          //message: response.backendMessage,
          message: "Message was sent succesfully",
          config: {duration: 5000}
        })

        this.ref.close(true);
      })
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  onSupplierCountryChange() {
    if (this.selectedCountry === 'Schweiz')  {
      this.selectedCurrency = 'CHF'
    } else  {
      this.selectedCurrency = 'EUR'
    }
  }
}
