export function getShortFormatDate(
  date: Date,
  format: 'dd.mm.yyyy' | 'yyyy-mm-dd' = 'dd.mm.yyyy'
): string {
  const day = ('0' + date.getDate()).slice(-2)
  const month = ('0' + (date.getMonth() + 1)).slice(-2)
  const year = date.getFullYear()

  if (format === 'dd.mm.yyyy') {
    return `${day}.${month}.${year}`
  }

  return `${year}-${month}-${date}`
}

export function formatDateToISOString(
  value: string | null,
  splitter = '.'
): string {
  if (value) {
    const [day, month, year] = value.split(splitter)
    return new Date(`${month}/${day}/${year}`).toISOString()
  }

  return value
}

