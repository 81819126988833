<form [formGroup]="saveDatamolinoForm">
  <div class="grid formgrid">
    <div class="field col-12 md:col-12 p-fluid">
      <label for="text">Nachricht an Datamolino (Nachricht wird automatisch übersetzt):</label>
      <textarea
        formControlName="text"
        id="text"
        pInputTextarea
        rows="5"
      ></textarea>
    </div>
    <div class="field col-12 md:col-12 text-right">
      <p-button
        (onClick)="cancel()"
        icon="pi pi-times"
        label="Abbrechen"
        styleClass="p-button-danger mr-2"
      ></p-button>
      <p-button
        (onClick)="send()"
        [disabled]="(loading$ | async) || saveDatamolinoForm.invalid"
        icon="pi pi-check"
        label="Fehlerhaftes Mapping melden"
      ></p-button>
    </div>
  </div>
</form>
