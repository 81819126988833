import { Action, createReducer, on } from '@ngrx/store';
import * as OpenInvoiceActions from '../actions/open-invoice.actions';
import {
  adapter,
  initialState as openInvoiceEntityInitialState,
  OpenInvoiceEntityState,
} from '../entities/open-invoice.entity';
import { Loading } from '@shared/models/loading.model';
import { act } from '@ngrx/effects';
import { stat } from 'fs';
import _ from 'lodash';
export const featureKey = 'openInvoice';

export type State = OpenInvoiceEntityState;

export const initialState: State = openInvoiceEntityInitialState;

const OpenInvoiceReducer = createReducer(
  initialState,

  on(
    OpenInvoiceActions.loadAllOpenInvoiceData,
    (state): State => ({
      ...state,
      status: Loading.Pending,
    })
  ),

  on(OpenInvoiceActions.loadAllOpenInvoiceDataSuccess, (state, action) =>
    adapter.setAll(action.openInvoice, {
      ...state,
      status: Loading.Resolved,
    })
  ),

  on(OpenInvoiceActions.removeSelectedOpenInvoiceData, (state, action) => {
    // eslint-disable-next-line no-var
    var entities = { ...state.entities };

    entities[action?.openInvoice?.abacusInvoiceNo] = null;
    return {
      ...state,
      entities,
    };
  }),

  on(
    OpenInvoiceActions.selectOrderConfirmationPdfSuccess,
    (state, action): State => ({
      ...state,
      orderConfirmationPdf: action.base64,
    })
  ),

  on(
    OpenInvoiceActions.selectOrderConfirmationPdfFailure,
    (state, action): State => ({
      ...state,
      orderConfirmationPdf: null,
    })
  ),

  on(OpenInvoiceActions.selectInvoicePdfSuccess, (state, action) => ({
    ...state,
    invoicePdf: action.base64,
  })),

  on(OpenInvoiceActions.selectInvoicePdfFailure, (state, action) => ({
    ...state,
    invoicePdf: null,
  })),

  on(
    OpenInvoiceActions.loadAllOpenInvoiceDataFailure,
    (state): State => ({
      ...state,
      status: Loading.Rejected,
    })
  ),

  on(
    OpenInvoiceActions.selectedOpenInvoice,
    (state, action): State => ({
      ...state,
      selectedOpenInvoice: action.openInvoice.abacusInvoiceNo,

      //reset other values
      orderConfirmationPdf: null,
      invoicePdf: null,
    })
  ),

  on(
    OpenInvoiceActions.clearSelectedOpenInvoice,
    (state): State => ({
      ...state,
      selectedOpenInvoice: null,
    })
  )
);

export function reducer(state: State | undefined, action: Action): State {
  return OpenInvoiceReducer(state, action);
}
