<form [formGroup]="createNewSupplierForm">
  <div class="grid formgrid">
      <div class="col-12 md:col-12 p-fluid">
        <br>
        <div class="field py-2">
          <div class="p-float-label">
            <input
              formControlName="supplierNameInput"
              id="supplierNameInput"
              pInputText
              type="text"
            />
            <label for="supplierNameInput">Name</label>
          </div>
        </div>

        <div class="nextToEachOtherContainer">
          <div>
            <div class="field py-2">
              <div class="p-float-label">
                <input
                  formControlName="supplierZipCodeInput"
                  id="supplierZipCodeInput"
                  pInputText
                  type="text"
                />
                <label for="supplierZipCodeInput">PLZ:</label>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-3 p-fluid">
            <div class="field py-2">
              <div class="p-float-label">
                <input
                  formControlName="supplierCityInput"
                  id="supplierCityInput"
                  pInputText
                  type="text"
                />
                <label for="supplierCityInput">Ort:</label>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-3 p-fluid">
            <div class="field py-2">
              <div class="p-float-label">
                <input
                  formControlName="supplierStreetInput"
                  id="supplierStreetInput"
                  pInputText
                  type="text"
                />
                <label for="supplierStreetInput">Strasse:</label>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2 p-fluid">
            <div class="field py-2">
              <div class="p-float-label">
                <input
                  formControlName="supplierStreetNoInput"
                  id="supplierStreetNoInput"
                  pInputText
                  type="text"
                />
                <label for="supplierStreetNoInput">Hausnummer:</label>
              </div>
            </div>
          </div>
          <div class="col-12 md:col-2 p-fluid">

            <div class="field py-2">
              <div class="p-float-label">
                <p-dropdown
                  formControlName="supplierCountryInput"
                  [options]="countries"
                  (onChange)="onSupplierCountryChange();"
                  [(ngModel)]="selectedCountry"
                  autoDisplayFirst="false"
                  optionValue="name"
                  id="supplierCountryInput"
                  readonly="true"
                  title="Sollte es ein kein Schweizer Lieferant sein, bitte diesen im Abacus anlegen."
                  optionLabel="name">
                </p-dropdown>
                <label for="supplierCountryInput">Land:</label>
              </div>
            </div>
          </div>
        </div>

        <div class="field py-2">
          <div class="p-float-label">
            <p-dropdown
              formControlName="dropdownCurrency"
              [options]="currencies"
              [(ngModel)]="selectedCurrency"
              optionValue="name"
              id="dropdownCurrency"
              readonly="true"
              title="Sollte es ein kein Schweizer Lieferant sein, bitte diesen im Abacus anlegen."
              optionLabel="name">
            </p-dropdown>
            <label  for="dropdownCurrency">Währung:</label>
          </div>
        </div>

        <div class="field py-2">
          <div class="p-float-label">
            <input
              formControlName="supplierTaxCodeInput"
              id="supplierTaxCodeInput"
              pInputText
              type="text"
            />
            <label for="supplierTaxCodeInput">MwSt.-Nummer:</label>
            <div *ngIf="createNewSupplierForm.get('supplierTaxCodeInput').invalid && createNewSupplierForm.get('supplierTaxCodeInput').touched" class="error-message">
              MwSt.-Nummer darf maximal 20 Zeichen lang sein!
            </div>
          </div>
        </div>

        <div class="field py-2">
          <div class="p-float-label">
            <input
              formControlName="supplierIBanCodeInput"
              id="supplierIBanCodeInput"
              pInputText
              type="text"
            />
            <label for="supplierIBanCodeInput">IBAN:</label>
            <div *ngIf="createNewSupplierForm.get('supplierIBanCodeInput').invalid && createNewSupplierForm.get('supplierIBanCodeInput').touched" class="error-message">
              IBAN muss exakt 21 Zeichen lang sein!
            </div>
          </div>
        </div>
    </div>
    <div class="field col-12 md:col-12 text-right">
      <p-button
        (onClick)="cancel()"
        icon="pi pi-times"
        label="Abbrechen"
        styleClass="p-button-danger mr-2"
      ></p-button>
      <p-button
        (onClick)="send()"
        [disabled]="(loading$ | async) || createNewSupplierForm.invalid"
        icon="pi pi-check"
        label="Lieferanten anlegen"
      ></p-button>
    </div>
  </div>
</form>
