import {User} from '@auth/models/user.model'
import {createReducer, on} from '@ngrx/store'
import * as AuthActions from '../actions/auth.actions'

export const featureKey = 'auth'

export interface State {
  user: User | null
}

export const initialState: State = {
  user: null
}

export const reducer = createReducer(
  initialState,

  on(
    AuthActions.setAuthUser,
    (state, action): State => ({
      ...state,
      user: action.user
    })
  ),

  on(
    AuthActions.clearAuthUser,
    (state): State => ({
      ...state,
      user: null
    })
  )
)
