<mat-sidenav-container class="sidenav-container">
  <mat-sidenav #sidenav class="sidenav" role="navigation">
    <nav-list
      (logout)="sidenav.close(); logout()"
      (sidenavClose)="sidenav.close()"
      [user]="user$ | async"
    ></nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <nav-header
      (sidenavToggle)="sidenav.toggle()"
      [user]="user$ | async"
    ></nav-header>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>
