import { Action, createReducer, on } from '@ngrx/store';
import * as OrderConfirmationsActions from '@order-confirmations/actions/order-confirmations.actions';
import {
  adapter,
  initialState as orderConfirmationsEntityInitialState,
  OrderConfirmationsEntityState,
} from '@order-confirmations/entities/order-confirmations.entity';
import { Loading } from '@shared/models/loading.model';

export const featureKey = 'orderConfirmations';

export type State = OrderConfirmationsEntityState;

export const initialState: State = orderConfirmationsEntityInitialState;

const orderConfirmationsReducer = createReducer(
  initialState,

  on(
    OrderConfirmationsActions.loadAllOrderConfirmationData,
    (state): State => ({
      ...state,
      status: Loading.Pending,
    })
  ),

  on(
    OrderConfirmationsActions.loadAllOrderConfirmationDataSuccess,
    (state, action) =>
      adapter.setAll(action.orderConfirmations, {
        ...state,
        status: Loading.Resolved,
      })
  ),

  on(
    OrderConfirmationsActions.removeSelectedOrderConfirmationData,
    (state, action) => {
      // eslint-disable-next-line no-var
      var entities = { ...state.entities };

      entities[action?.orderConfirmation?.purchaseOrderNo] = null;
      return {
        ...state,
        entities,
      };
    }
  ),

  on(
    OrderConfirmationsActions.loadAllOrderConfirmationDataFailure,
    (state): State => ({
      ...state,
      status: Loading.Rejected,
    })
  ),

  //Order Confirmation Pdf
  on(
    OrderConfirmationsActions.loadOrderConfirmationPDF,
    (state): State => ({
      ...state,
    })
  ),

  on(
    OrderConfirmationsActions.loadOrderConfirmationPDFSuccess,
    (state, action) => ({
      ...state,
      orderConfirmationPdf: action.apiResponse.backendMessage,
    })
  ),

  on(
    OrderConfirmationsActions.loadOrderConfirmationPDFFailure,
    (state): State => ({
      ...state,
      orderConfirmationPdf: null,
    })
  ),

  on(
    OrderConfirmationsActions.clearOrderConfirmationPDF,
    (state): State => ({
      ...state,
      orderConfirmationPdf: null,
    })
  ),

  //Order Pdf
  on(
    OrderConfirmationsActions.loadOrderPDF,
    (state): State => ({
      ...state,
    })
  ),

  on(OrderConfirmationsActions.loadOrderPDFSuccess, (state, action) => ({
    ...state,
    orderPdf: action.apiResponse.backendMessage,
  })),

  on(
    OrderConfirmationsActions.loadOrderPDFFailure,
    (state): State => ({
      ...state,
      orderPdf: null,
    })
  ),

  on(
    OrderConfirmationsActions.clearOrderPDF,
    (state): State => ({
      ...state,
      orderPdf: null,
    })
  ),

  //Order Delivery Chain Change Info
  on(
    OrderConfirmationsActions.loadDeliveryChainChangeInfo,
    (state): State => ({
      ...state,
    })
  ),

  on(
    OrderConfirmationsActions.loadDeliveryChainChangeInfoSuccess,
    (state, action) => ({
      ...state,
      deliveryChainChangeInfo: action.deliveryChainChangeInfo.fullText,
    })
  ),

  on(
    OrderConfirmationsActions.loadDeliveryChainChangeInfoFailure,
    (state): State => ({
      ...state,
      deliveryChainChangeInfo: null,
    })
  ),

  on(
    OrderConfirmationsActions.selectOrderConfirmation,
    (state, action): State => ({
      ...state,
      selectedOrderConfirmation: parseInt(
        action.orderConfirmation.orderConfirmationNo
      ),

      //reset other values
      orderPdf: null,
      orderConfirmationPdf: null,
      deliveryChainChangeInfo: null,
    })
  ),

  on(
    OrderConfirmationsActions.clearSelectedOrderConfirmation,
    (state): State => ({
      ...state,
      selectedOrderConfirmation: null,
    })
  )
);

export function reducer(state: State | undefined, action: Action): State {
  return orderConfirmationsReducer(state, action);
}
