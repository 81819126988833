import {Action, createReducer, on} from '@ngrx/store'
import * as ProjectsManagersActions from '@order-confirmations/actions/project-managers.actions'
import {
  adapter,
  initialState as projectManagersEntityInitialState,
  ProjectManagersEntityState
} from '@order-confirmations/entities/project-managers.entity'
import {Loading} from '@shared/models/loading.model'

export const featureKey = 'projectManagers'

export type State = ProjectManagersEntityState

export const initialState: State = projectManagersEntityInitialState

const projectManagersReducer = createReducer(
  initialState,

  on(
    ProjectsManagersActions.loadAllProjectManagers,
    (state): State => ({
      ...state,
      status: Loading.Pending
    })
  ),

  on(
    ProjectsManagersActions.loadAllProjectManagersSuccess,
    (state, action) =>
      adapter.setAll(action.projectManagers, {
        ...state,
        status: Loading.Resolved
      })
  ),

  on(
    ProjectsManagersActions.loadAllProjectManagersFailure,
    (state): State => ({
      ...state,
      status: Loading.Rejected
    })
  ),

  on(
    ProjectsManagersActions.selectProjectManager,
    (state, action): State => ({
      ...state,
      selectedProjectManager: parseInt(action.projectManager.projectManagerNumber)
    })
  ),

  on(
    ProjectsManagersActions.clearSelectedProjectManager,
    (state): State => ({
      ...state,
      selectedProjectManager: null
    })
  )
)

export function reducer(state: State | undefined, action: Action): State {
  return projectManagersReducer(state, action)
}
