import {Action, createReducer, on} from '@ngrx/store'
import {closeSnackbar, openSnackbar} from '../actions/snackbar.actions'

export const featureKey = 'snackbar'

export interface State {
  show: boolean
}

const initialState: State = {
  show: false
}

const snackbarReducer = createReducer(
  initialState,

  on(
    openSnackbar,
    (state, action): State => ({
      ...state,
      show: true
    })
  ),

  on(
    closeSnackbar,
    (state, action): State => ({
      ...state,
      show: false
    })
  )
)

export function reducer(state: State | undefined, action: Action): State {
  return snackbarReducer(state, action)
}
