/* eslint-disable @typescript-eslint/naming-convention */
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Injectable} from '@angular/core'
import {Role, RolesEnum, User} from '@auth/models/user.model'
import {featureKey} from '@auth/reducers/auth.reducer'
import {Observable} from 'rxjs'
import {SERVER_API_URL} from 'src/app/app.constants'

const JWTToken = '__jwt_token__'
const TSToken = '__ts_token__'

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private url = SERVER_API_URL
  private auth = 'VdjCKidks7qTEyuU6QeJtezZ3PeCHhZQ2yHJ3kPtcfTN8BjVqU';

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application2/json',
      'x-apikey': 'VdjCKidks7qTEyuU6QeJtezZ3PeCHhZQ2yHJ3kPtcfTN8BjVqU'
    })
  }

  constructor(private http: HttpClient) {
  }

  getLoginData(email: string, password: string): Observable<User> {
    return this.http.get<User>(
      `${this.url}/getLoginData?auth=${this.auth}&username=${email}&password=${password}`,
      this.httpOptions
    )
  }

  getLoginDataFromAuthToken(token: string): Observable<User> {
    return this.http.get<User>(
      `${this.url}/getLoginDataFromAuthTokenAlternative?token=${token}`,
      this.httpOptions
    )
  }

  getAuthUserToken(): User | null {
    try {
      const authToken =
        JSON.parse(localStorage.getItem(featureKey)) || (null as User | null)

      return authToken
    } catch {
      return null
    }
  }

  setAuthUserToken(user: User): void {
    localStorage.setItem(featureKey, JSON.stringify(user))
  }

  setJWTToken(jwtToken: string) {
    localStorage.setItem(JWTToken, jwtToken)
  }

  getJWTToken(): string {
    const authToken = localStorage.getItem(JWTToken)
    if (!authToken || authToken === 'null' || authToken === 'undefined') {
      return ''
    }
    return authToken
  }

  setTsToken(tsToken: string) {
    localStorage.setItem(TSToken, tsToken)
  }

  getTsToken(): string {
    const tsToken = localStorage.getItem(TSToken) as string
    if (!tsToken || tsToken === 'null' || tsToken === 'undefined') {
      return ''
    }
    return tsToken
  }

  removeAuthUserToken(): void {
    localStorage.removeItem(featureKey)
  }

  isAuthenticated(): boolean {
    const auth = localStorage.getItem(featureKey)

    try {
      const authObj = JSON.parse(auth) as User

      return !!authObj
    } catch {
      return false
    }
  }

  hasRole(roles: Role[], roleName: RolesEnum): boolean {
    return roles.some((role) => role.name === roleName)
  }
}
