import {createAction, props} from '@ngrx/store';
import {ProjectManager} from "@order-confirmations/models/project-manager.model";

export const loadAllProjectManagers = createAction(
  '[ProjectManagers] Load All Project Managers'
)

export const loadAllProjectManagersSuccess = createAction(
  '[ProjectManagers] Load All Project Managers Success',
  props<{ projectManagers: ProjectManager[] }>()
)

export const loadAllProjectManagersFailure = createAction(
  '[ProjectManagers] Load All Project Managers Failure',
  props<{ error?: string | null }>()
)

export const selectProjectManager = createAction(
  '[ProjectManagers] Select Project Manager',
  props<{
    projectManager?: ProjectManager
  }>()
)

export const clearSelectedProjectManager = createAction(
  '[ProjectManagers] Clear Selected Project Manager'
)
