import {createEntityAdapter, EntityState} from '@ngrx/entity'
import {Loading} from '@shared/models/loading.model'
import {ProjectManager} from "@order-confirmations/models/project-manager.model";

interface ProjectManagersInitialState {
  status: Loading
  selectedProjectManager: number | null
}

export interface ProjectManagersEntityState
  extends EntityState<ProjectManager>,
    ProjectManagersInitialState {
}

function sortComparer(a: ProjectManager, b: ProjectManager): number {

  return parseInt(a.projectNo) - parseInt(b.projectManagerNumber)
}

export const adapter = createEntityAdapter<ProjectManager>({
  selectId: (state) => state.projectNo,
  sortComparer
})

export const initialState =
  adapter.getInitialState<ProjectManagersInitialState>({
    status: Loading.Idle,
    selectedProjectManager: null
  })
