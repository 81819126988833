import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthModule} from '@auth/auth.module';
import {InvoiceModule} from '@invoice/invoice.module';
import {OrderUploadModule} from '@order-upload/order-upload.module';

const routes: Routes = [
  {
    path: '',
    loadChildren: (): Promise<typeof InvoiceModule> =>
      import('./invoice/invoice.module').then((m) => m.InvoiceModule),
  },
  {
    path: '',
    loadChildren: (): Promise<typeof OrderUploadModule> =>
      import('./order-upload/order-upload.module').then(
        (m) => m.OrderUploadModule
      ),
  },
  {
    path: 'settings',
    loadChildren: (): Promise<typeof OrderUploadModule> =>
      import('./settings/settings.module').then(
        (m) => m.SettingsModule
      ),
  },
  {
    path: 'auth',
    loadChildren: (): Promise<typeof AuthModule> =>
      import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {path: '', redirectTo: '', pathMatch: 'full'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {relativeLinkResolution: 'legacy'})],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
