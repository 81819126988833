import {registerLocaleData} from '@angular/common';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import localeDECH from '@angular/common/locales/de-CH';
import {LOCALE_ID, NgModule} from '@angular/core';
import {MatSidenavModule} from '@angular/material/sidenav';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule} from '@angular/router';
import {CoreModule} from '@core/core.module';
import {EffectsModule} from '@ngrx/effects';
import {StoreModule} from '@ngrx/store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {SharedModule} from '@shared/shared.module';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {metaReducers, ROOT_REDUCERS} from './reducers';
import {AuthInterceptorService} from "@auth/auth.interceptor.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {TimeoutInterceptor} from "@auth/timeout.interceptor-service";
import {MatSortModule} from "@angular/material/sort";
import {MatTableModule} from "@angular/material/table";

registerLocaleData(localeDECH);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule,
    AppRoutingModule,
    HttpClientModule,
    MatTableModule,
    MatSortModule,
    CoreModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateSerializability: true,
        strictActionSerializability: true,
        strictActionWithinNgZone: true,
        strictActionTypeUniqueness: true,
      },
    }),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'Invoice 2.0',
      maxAge: 25,
      logOnly: environment.production,
    }),
    MatSidenavModule,
  ],
  providers: [{provide: LOCALE_ID, useValue: 'de-CH'},
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TimeoutInterceptor,
      multi: true
    }
    ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
