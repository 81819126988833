<mat-toolbar class="expanded-toolbar" color="primary">
  <div class="sidenav-content">
    <button
      (click)="onToggleSidenav()"
      aria-label="Example icon-button with menu icon"
      class="example-icon"
      mat-icon-button
    >
      <mat-icon>menu</mat-icon>
    </button>
    <span>AB/RE-Portal</span>
  </div>
  <img alt="Muessig" src="assets/img/logos/logo.png" />
  <div>
    <ng-container *ngIf="user">
      <small>{{ user.name }}</small>
    </ng-container>
  </div>
</mat-toolbar>
