import {createAction, props} from '@ngrx/store'
import {MatSnackBarConfig} from '@angular/material/snack-bar'

export const openSnackbar = createAction(
  '[Snackbar] Open Snackbar',
  props<{ message: string; action?: string; config?: MatSnackBarConfig }>()
)

export const closeSnackbar = createAction(
  '[Snackbar] Close Snackbar',
  // eslint-disable-next-line ngrx/prefer-inline-action-props
  props<any>()
)
