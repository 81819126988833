import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Observable } from 'rxjs'
import {AuthService} from "@auth/services/auth.service";
import {Injectable} from "@angular/core";

@Injectable()
export class AuthInterceptorService implements HttpInterceptor {
  // Define the received char map from the server
  private readonly receivedCharMap = atob(this.authService.getTsToken())
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const currentTimestamp = new Date().getTime()
    const tsToken = this.encodeTimestamp(currentTimestamp)

    // Clone the request and add the x-apikey header
    const modifiedRequest = request.clone({
      headers: request.headers
        .set('x-apikey', 'VdjCKidks7qTEyuU6QeJtezZ3PeCHhZQ2yHJ3kPtcfTN8BjVqU')
        .append('Token', this.authService.getJWTToken())
        .append('tsToken', tsToken)
    })

    // Handle the modified request
    return next.handle(modifiedRequest)
  }

  private encodeTimestamp(timestamp: number): string {
    if (!this.receivedCharMap || this.receivedCharMap.length !== 10) {
      console.log('Token not valid: ' + this.receivedCharMap)
      return ''
    }
    console.log('Token valid: ' + this.receivedCharMap)
    const baseMap = '0123456789' // Define the base map
    const charMap: { [key: string]: string } = {}
    // Create the char map using the received value
    for (let i = 0; i < baseMap.length; i++) {
      charMap[baseMap.charAt(i)] = this.receivedCharMap.charAt(i)
    }
    let encodedString = ''
    for (let i = 0; i < timestamp.toString().length; i++) {
      const timestampChar = timestamp.toString().charAt(i)
      encodedString += charMap.hasOwnProperty(timestampChar)
        ? charMap[timestampChar]
        : timestampChar
    }
    console.log('end of function: ' + encodedString)
    return btoa(encodedString)
  }
}
