import {Component, Input} from '@angular/core';
import {PdfService} from "@shared/services/pdf.service";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'pdf-compare',
  templateUrl: './pdf-compare.component.html',
  styleUrls: ['./pdf-compare.component.css'],
})
export class PdfCompareComponent {
  @Input() firstPdf: string = null;
  @Input() secondPdf: string = null;
  @Input() label1: string = null;
  @Input() label2: string = null;

  constructor(private pdfService: PdfService) {
  }

  openPdf(pdfAsStr: string) {
    this.pdfService.openPDF(pdfAsStr);
  }
}
