import {InjectionToken} from '@angular/core';
import * as AuthActions from '@auth/actions/auth.actions';
import * as fromAuth from '@auth/reducers/auth.reducer';
import {Action, ActionReducer, ActionReducerMap, MetaReducer,} from '@ngrx/store';
import * as fromOrderConfirmations from '@order-confirmations/reducers/order-confirmations.reducer';
import * as fromProjectManagers from '@order-confirmations/reducers/project-managers.reducer';
import * as fromSnackbar from '@shared/reducers/snackbar.reducer';
import * as fromOpenInvoice from '../open-invoice/reducers/open-invoice.reducer';

export interface RootState {
  [fromAuth.featureKey]: fromAuth.State;
  [fromOrderConfirmations.featureKey]: fromOrderConfirmations.State;
  [fromProjectManagers.featureKey]: fromProjectManagers.State;
  [fromOpenInvoice.featureKey]: fromOpenInvoice.State;
  [fromSnackbar.featureKey]: fromSnackbar.State;
}

export const ROOT_REDUCERS = new InjectionToken<ActionReducerMap<RootState, Action>>('Root reducers token', {
  factory: (): ActionReducerMap<RootState, Action> => ({
    [fromAuth.featureKey]: fromAuth.reducer,
    [fromOrderConfirmations.featureKey]: fromOrderConfirmations.reducer,
    [fromOpenInvoice.featureKey]: fromOpenInvoice.reducer,
    [fromProjectManagers.featureKey]: fromProjectManagers.reducer,
    [fromSnackbar.featureKey]: fromSnackbar.reducer,
  }),
});

function resetStateOnLogout(reducer: ActionReducer<any>): ActionReducer<any> {
  // eslint-disable-next-line space-before-function-paren
  return function (state, action): ActionReducer<any> {
    if (action.type === AuthActions.clearAuthUser.type) {
      state = undefined;
    }

    return reducer(state, action);
  };
}

export const metaReducers: MetaReducer<RootState>[] = [resetStateOnLogout];
