<mat-nav-list>
  <a (click)="onSidenavClose()" [routerLink]="['/']" mat-list-item>
    <mat-icon>home</mat-icon>
    <span class="nav-caption">Rechnungen</span>
  </a>
  <a (click)="onSidenavClose()" [routerLink]="['/upload-tool']" mat-list-item>
    <mat-icon>upload</mat-icon>
    <span class="nav-caption">ABs manuell hochladen</span>
  </a>
  <a (click)="onSidenavClose()" [routerLink]="['/settings']" mat-list-item>
    <mat-icon>settings</mat-icon>
    <span class="nav-caption">Einstellungen</span>
  </a>
  <a
    (click)="logoutAction()"
    *ngIf="user; else login"
    mat-list-item
    routerLink="/auth/login"
  >
    <mat-icon>meeting_room</mat-icon>
    <span class="nav-caption">Logout</span>
  </a>
  <ng-template #login>
    <a
      (click)="onSidenavClose()"
      [routerLink]="['auth', 'login']"
      mat-list-item
    >
      <mat-icon>login</mat-icon>
      <span class="nav-caption">Login</span>
    </a>
  </ng-template>
</mat-nav-list>
