import { createAction, props } from '@ngrx/store';
import { OrderConfirmation } from '@order-confirmations/models/order-confirmation.model';
import { ApiResponse } from '@shared/models/apiresponse.model';
import { DeliveryChainChangeInfo } from '@order-confirmations/models/delivery-chain-change-info.model';

export const loadAllOrderConfirmationData = createAction(
  '[OrderConfirmations] Load All Order Confirmations'
);

export const removeSelectedOrderConfirmationData = createAction(
  '[OpenInvoice] Remove  Selected Order Confirmation Data',
  props<{ orderConfirmation: OrderConfirmation }>()
);

export const loadAllOrderConfirmationDataSuccess = createAction(
  '[OrderConfirmations] Load All Order Confirmations Success',
  props<{ orderConfirmations: OrderConfirmation[] }>()
);

export const loadAllOrderConfirmationDataFailure = createAction(
  '[OrderConfirmations] Load All Order Confirmations Failure',
  props<{ error?: string | null }>()
);

export const loadOrderConfirmationPDF = createAction(
  '[OrderConfirmationPDF] Load Order Confirmation PDF',
  props<{ orderId: string }>()
);

export const loadOrderConfirmationPDFSuccess = createAction(
  '[OrderConfirmationPDF] Load Order Confirmation PDF Success',
  props<{ orderId: string; apiResponse: ApiResponse }>()
);

export const loadOrderConfirmationPDFFailure = createAction(
  '[OrderConfirmationPDF] Load Order Confirmation PDF Failure',
  props<{ error?: string | null }>()
);

export const clearOrderConfirmationPDF = createAction(
  '[OrderConfirmationPDF] Clear Order Confirmation PDF'
);

export const loadOrderPDF = createAction(
  '[OrderPDF] Load Order PDF',
  props<{ orderId: string }>()
);

export const loadOrderPDFSuccess = createAction(
  '[OrderPDF] Load Order PDF Success',
  props<{ orderId: string; apiResponse: ApiResponse }>()
);

export const loadOrderPDFFailure = createAction(
  '[OrderPDF] Load Order PDF Failure',
  props<{ error?: string | null }>()
);

export const clearOrderPDF = createAction(
  '[OrderPDF] Clear Selected Order PDF'
);

export const loadDeliveryChainChangeInfo = createAction(
  '[OrderDeliveryChainChangeInfo] Load Order delivery chain change infos',
  props<{ orderId: string }>()
);

export const loadDeliveryChainChangeInfoSuccess = createAction(
  '[OrderDeliveryChainChangeInfo] Load Order delivery chain change infos Success',
  props<{ orderId: string; deliveryChainChangeInfo: DeliveryChainChangeInfo }>()
);

export const loadDeliveryChainChangeInfoFailure = createAction(
  '[OrderDeliveryChainChangeInfo] Load Order delivery chain change infos Failure',
  props<{ error?: string | null }>()
);

export const selectOrderConfirmation = createAction(
  '[OrderConfirmations] Select Order Confirmation',
  props<{
    orderConfirmation?: OrderConfirmation;
  }>()
);

export const clearSelectedOrderConfirmation = createAction(
  '[OrderConfirmations] Clear Selected Order Confirmation'
);
