import { Component, OnInit } from '@angular/core';
import { AuthFacadeService } from '@auth/facades/auth-facade.service';
import { User } from '@auth/models/user.model';
import { AuthService } from '@auth/services/auth.service';
import { PrimeNGConfig } from 'primeng/api';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  user$: Observable<User | null>;

  constructor(
    private authService: AuthService,
    private authFacade: AuthFacadeService,
    private config: PrimeNGConfig
  ) {
    this.user$ = this.authFacade.user$;
  }

  ngOnInit(): void {
    const authUserToken = this.authService.getAuthUserToken();
    this.setTranslation();

    if (authUserToken) {
      this.authFacade.setAuthUser(authUserToken);
    }
  }

  setTranslation(): void {
    this.config.setTranslation({
      firstDayOfWeek: 1,
      dayNames: [
        'Sonntag',
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
      ],
      dayNamesShort: ['Son', 'Mon', 'Die', 'Mit', 'Don', 'Fre', 'Sam'],
      dayNamesMin: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      monthNames: [
        'Januar',
        'Februar',
        'März',
        'April',
        'Mai',
        'Juni',
        'Juli',
        'August',
        'September',
        'Oktober',
        'November',
        'Dezember',
      ],
      weekHeader: 'W',
      monthNamesShort: [
        'Jan',
        'Feb',
        'Mär',
        'Apr',
        'Mai',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Okt',
        'Nov',
        'Dez',
      ],
      today: 'Heute',
    });
  }

  logout(): void {
    this.authService.removeAuthUserToken()
    window.location.href = 'https://login.muessig.app/login'
    this.authFacade.clearAuthUser();
  }
}
