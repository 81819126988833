/* eslint-disable no-shadow */

/* eslint-disable @typescript-eslint/naming-convention */
export enum RolesEnum {
  Technician = 'TECHNICIAN',
  Supplier = 'SUPPLIER',
  InvoiceAdmin = 'INVOICE_ADMIN',
  DoubleVisaUser = 'DOUBLE_VISA_USER'
}

export interface Role {
  name: string
  description: string
  persistanceUnit: string
}

export interface User {
  abacusName: string | null
  email: string
  isActivated: boolean
  name: string | null
  password: string | null
  persistanceUnit: string | null
  roles: Role[]
  sites: any[]
  slackEmail: string
  technicianId: number
}
