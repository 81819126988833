import {Injectable} from '@angular/core'
import {Store} from '@ngrx/store'
import {ActionProps} from '@invoice/types'
import * as SnackbarActions from '@shared/actions/snackbar.actions'

@Injectable({
  providedIn: 'root'
})
export class SnackbarFacadeService {
  constructor(private readonly store: Store) {
  }

  openSnackbar(
    props: ActionProps<ReturnType<typeof SnackbarActions.openSnackbar>>
  ): void {
    this.store.dispatch(SnackbarActions.openSnackbar(props))
  }
}
