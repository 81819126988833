/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/member-ordering */
import {Overlay, OverlayPositionBuilder, OverlayRef} from '@angular/cdk/overlay'
import {ComponentPortal} from '@angular/cdk/portal'
import {Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2, TemplateRef} from '@angular/core'
import {CustomTooltipComponent} from '@shared/directives/tooltip/tooltip.component'
import {take} from 'rxjs/operators'

@Directive({
  selector: '[appTooltip]'
})
export class TooltipDirective implements OnInit, OnDestroy {
  @Input() showToolTip = true
  @Input() appTooltip: string
  @Input() contentTemplate: TemplateRef<any>
  @Input() context: any

  private overlayRef: OverlayRef
  private tooltipInstance
  private mouseInTooltip = false
  private hasListeners = false

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private r2: Renderer2
  ) {
  }

  ngOnInit(): void {
    if (!this.showToolTip) {
      return
    }

    this.overlayRef = this.overlay.create()
  }

  ngOnDestroy(): void {
    this.closeToolTip()
  }

  @HostListener('mouseenter')
  show(): void {
    if (this.overlayRef && !this.overlayRef.hasAttached()) {
      this.tooltipInstance = this.overlayRef.attach(
        new ComponentPortal(CustomTooltipComponent)
      ).instance
      this.overlayRef.updatePositionStrategy(
        this.overlayPositionBuilder
          .flexibleConnectedTo(this.elementRef)
          .withPositions([
            {
              originX: 'center',
              originY: 'center',
              overlayX: 'center',
              overlayY: 'top'
            }
          ])
      )
      this.overlayRef.updatePosition()

      this.tooltipInstance.text = this.appTooltip
      this.tooltipInstance.contentTemplate = this.contentTemplate
      this.tooltipInstance.context = this.context

      this.tooltipInstance?.show()

      this.tooltipInstance
        .afterHidden()
        .pipe(take(1))
        .subscribe(() => {
          this.overlayRef.detach()
        })
      if (!this.hasListeners) {
        this.hasListeners = true
        this.r2.listen(this.overlayRef.overlayElement, 'mouseleave', () => {
          this.mouseInTooltip = false
          this.hide()
        })

        this.r2.listen(this.overlayRef.overlayElement, 'mouseenter', () => {
          this.mouseInTooltip = true
        })
      }
    }
  }

  @HostListener('mouseleave')
  hide(): void {
    this.closeToolTip()
  }

  private closeToolTip(): void {
    setTimeout(() => {
      if (!this.mouseInTooltip) {
        this.tooltipInstance?._onHide.next()
      }
    }, 20)
  }
}
