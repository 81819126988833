import { createAction, props } from '@ngrx/store';
import { OpenInvoice } from '../models/open-invoice-model';

export const loadAllOpenInvoiceData = createAction(
  '[OpenInvoice] Load All Open Data'
);

export const removeSelectedOpenInvoiceData = createAction(
  '[OpenInvoice] Remove  Selected Open Invoice Data',
  props<{ openInvoice: OpenInvoice }>()
);

export const loadAllOpenInvoiceDataSuccess = createAction(
  '[OpenInvoice] Load All Open Data Success',
  props<{ openInvoice: OpenInvoice[] }>()
);

export const loadAllOpenInvoiceDataFailure = createAction(
  '[OpenInvoice] Load All Order Confirmations Failure',
  props<{ error?: string | null }>()
);

export const selectedOpenInvoice = createAction(
  '[OpenInvoice] Select Open Invoice',
  props<{
    openInvoice?: OpenInvoice;
  }>()
);

export const selectOrderConfirmationPdf = createAction(
  '[OpenInvoice] Select Order Confirmation Pdf',
  props<{
    orderNo: string;
    user: string;
  }>()
);

export const selectOrderConfirmationPdfSuccess = createAction(
  '[OpenInvoice] Select Order Confirmation Pdf Success',
  props<{
    base64: string;
  }>()
);

export const selectOrderConfirmationPdfFailure = createAction(
  '[OpenInvoice] Select Order Confirmation Pdf Failure',
  props<{ error?: string | null }>()
);

export const selectInvoicePdf = createAction(
  '[OpenInvoice] Select Invoice Pdf',
  props<{
    supplierId: string;
    user: string;
    invoiceNo: string;
  }>()
);

export const selectInvoicePdfSuccess = createAction(
  '[OpenInvoice] Select Invoice  Pdf Sucess',
  props<{
    base64: string;
  }>()
);

export const selectInvoicePdfFailure = createAction(
  '[OpenInvoice] Select Invoice Pdf Failure',
  props<{ error?: string | null }>()
);

export const clearSelectedOpenInvoice = createAction(
  '[OpenInvoice] Clear Selected Order Confirmation'
);
