import {Component, NgModule, OnDestroy, OnInit} from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from "primeng/dynamicdialog";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {SnackbarFacadeService} from "@shared/facades/snackbar-facade.service";
import {MessageService} from "@shared/services/message.service";
import {MessageRequest} from "@shared/models/message-request.model";

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  templateUrl: './send-datamolino-message-dialog.component.html',
  styleUrls: ['./send-datamolino-message-dialog.component.css'],
})

export class SendDatamolinoMessageDialogComponent implements OnDestroy {
  formSubscription: Subscription;
  message: MessageRequest | undefined;
  public saveDatamolinoForm = new FormGroup({
    text: new FormControl('Kostenart/Kostenstelle wurde nicht korrekt gemappt. Die Nummer ist auf dem Beleg ersichtlich und lautet XXXXXX/XXXX\nBestellnummer wurde nicht korrekt gemappt. Die Nummer ist auf dem Beleg ersichtlich und lautet XXXXXX', Validators.required)
  });
  private loadingBehaviourSubject = new BehaviorSubject<boolean>(false)
  public readonly loading$ = this.loadingBehaviourSubject.asObservable()

  constructor(
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private snackbarFacade: SnackbarFacadeService,
    private messageService: MessageService
  ) {
    this.message = config.data
  }

  cancel() {
    this.ref.close();
  }

  send() {

    this.loadingBehaviourSubject.next(true)
    this.messageService
      .sendDatamolinoMessage({
          ...this.message,
          text: this.saveDatamolinoForm.get('text').value
        }
      )
      .pipe(
        finalize(() => {
          this.loadingBehaviourSubject.next(false)
        })
      )
      .subscribe((response) => {
        this.snackbarFacade.openSnackbar({
          //message: response.backendMessage,
          message: "Nachricht wurde erfolgreich an Datamolino gesendet",
          config: {duration: 5000}
        })

        this.ref.close(true);
      })
  }

  ngOnDestroy(): void {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }
}
